import React from 'react';
import Layout from '../layout';
import Contact from '../components/Contact/Contact';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { Container, Grid, Hidden, Typography } from '@material-ui/core';
import JsxSeo from '../components/SEO/JsxSEO';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@material-ui/core/Box';

function ContactPage({ location }) {
  return (
    <Layout>
      <JsxSeo
        title="Contact us"
        location={location}
        description="Have an idea of bringing a product to life and you need help with business model, design & development? Let’s talk!"
      />
      <Contact />

      <Container maxWidth="sm">
        <Box mb={14}>
          <ContactForm />
        </Box>
      </Container>

      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid item md={6} sm={8}>
            <Typography variant="h2" gutterBottom>
              ...Or send us{' '}
              <Hidden smDown>
                <br />
              </Hidden>{' '}
              an email
            </Typography>
            <Typography variant="subtitle2">office@spicefactory.co</Typography>
          </Grid>
          <Grid item md={6} sm={4}>
            <StaticImage src="../../static/images/mail1.png" alt="mailbox" />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default ContactPage;
