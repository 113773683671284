import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stamp from '../Stamp/Stamp';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(6),
  },
  stamp: {
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth="xl" className={classes.hero}>
        <Typography variant="h1">Let’s work together</Typography>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="subtitle2" gutterBottom>
              Have an idea of bringing a product to life and you need help with business model, design & development?
              Let’s talk!
            </Typography>
          </Grid>
          <Grid item md={4} className={classes.stamp}>
            <Stamp />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default Contact;
