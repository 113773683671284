// @ts-ignore
import React, { Fragment } from 'react';
import { Select, MenuItem, TextField, makeStyles, InputLabel, FormControl, Typography } from '@material-ui/core';
import { JumboButton } from '../JumboButton/JumboButton';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formInput: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    [`& fieldset`]: {
      borderRadius: 0,
      borderColor: theme.palette.blackPepperDarker,
    },
    color: theme.palette.text.primary,
    width: '100%',
  },
}));

export function ContactForm() {
  const classes = useStyles();

  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [isSubmitted, setIsSubmitted] = React.useState(undefined);

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setIsSubmitted(true))
      .catch(() => setIsSubmitted(false));
  };

  return (
    <Fragment>
      {!isSubmitted && (
        <form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
          className={classes.root}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="bot-field" onChange={handleChange} />
          <input type="hidden" name="form-name" value="contact" />
          <TextField
            type="text"
            name="name"
            id="outlined-basic"
            label="Your name"
            variant="outlined"
            className={classes.formInput}
            fullWidth
            onChange={handleChange}
            required
          />
          <TextField
            type="text"
            name="company-name"
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            className={classes.formInput}
            fullWidth
            color="primary"
            onChange={handleChange}
            required
          />
          <TextField
            type="email"
            name="email"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            className={classes.formInput}
            fullWidth
            onChange={handleChange}
            required
          />
          <FormControl variant="outlined" className={classes.formInput} onChange={handleChange}>
            <InputLabel required id="demo-simple-select-outlined-label">
              Budget
            </InputLabel>
            <Select
              name="budget"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              fullWidth
              onChange={handleChange}
              label="Budget"
              required
            >
              <MenuItem value="Placeholder" disabled>
                <em>Project budget</em>
              </MenuItem>
              <MenuItem value={`less than $100k`}>Less than $100k</MenuItem>
              <MenuItem value={`$100k - $300k`}>$100k - $300k </MenuItem>
              <MenuItem value={`$300k and up`}>$300k and up</MenuItem>
            </Select>
          </FormControl>

          <TextField
            type="text"
            name="message"
            className={classes.formInput}
            id="outlined-multiline-basic"
            multiline
            fullWidth
            variant="outlined"
            rows={4}
            placeholder="Message (optional)"
            onChange={handleChange}
          />
          <JumboButton variant="contained" color="primary" type="submit">
            Send message
          </JumboButton>
        </form>
      )}
      <Typography variant="body1">
        {isSubmitted === undefined
          ? ''
          : isSubmitted
          ? 'Thanks! Our customer onboarding team will soon get in touch with you!'
          : 'Something went wrong 😒. Try again or use the email below.'}
      </Typography>
    </Fragment>
  );
}
